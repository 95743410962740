import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/home/home';
import Header from './components/Header/Header';
import { Helmet } from "react-helmet";
import React, {useEffect } from 'react';
import ReactGA from 'react-ga';
const TRACKING_ID = "G-BBP0XL61QX"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  
  return (
    // <BrowserRouter basename="/tests/philippecarvin">
    <BrowserRouter>
      <Helmet>
        <title>Philippe Carvin</title>
        <meta
          name="description"
          content="Philippe Carvin, Artiste, Peintre. Figuratif, dessins ou peintures rehaussées de couleur et de lumières tous les thèmes me passionnent dans une expression de plaisir et de libertés, Réaliser un pont mystérieux entre l'âme du tableaux et des personnages incluant le spectateur."
        />
      </Helmet>
      <Header></Header>
      <Routes>
        <Route path="/" element={<Home />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
