export const photos = [
    {
      src: "./tableaux/Cabaret 1 75x34.JPG",
      alt:"Cabaret 1 75x34 Philippe Carvin",
      width: 2.4,
      height: 3.6,
      title: "Cabaret 1 75x34",
      caption: "Cabaret 1 75x34",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Cabaret 2 81x65.JPG",
      alt:"Cabaret 2 81x65 Philippe Carvin",
      width: 3.2,
      height: 4,
      title: "Cabaret 2 81x65",
      caption: "Cabaret 2 81x65",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Cabaret 3 100x81.JPG",
      alt:"Cabaret 3 100x81 Philippe Carvin",
      width: 4,
      height: 5,
      title: "Cabaret 3 100x81",
      caption: "Cabaret 3 100x81",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Cabaret 4 100x81.JPG",
      alt:"Cabaret 4 100x81 Philippe Carvin",
      width: 4,
      height: 5,
      title: "Cabaret 4 100x81",
      caption: "Cabaret 4 100x81"
    },
    {
      src: "./tableaux/Cabaret 5 73x54.JPG",
      alt:"Cabaret 5 73x54 Philippe Carvin",
      width: 2.7,
      height: 3.7,
      title: "Cabaret 5 73x54",
      caption: "Cabaret 5 73x54",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Inde 2 46x38.JPG",
      alt:"Inde 2 46x38 Philippe Carvin",
    width: 1.9,
    height: 2.3,
    title: "Inde 2 46x38",
    caption: "Inde 2 46x38",
    loading: 'lazy'
    },
    {
      src: "./tableaux/Inde 3 46x38.JPG",
      alt:"Inde 3 46x38 Philippe Carvin",
      width: 1.9,
      height: 2.3,
      title: "Inde 3 46x38",
      caption: "Inde 3 46x38",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Maroc 3 61x50.JPG",
      alt:"Maroc 3 61x50 Philippe Carvin",
      width: 2.5,
      height: 3,
      title: "Maroc 3 61x50",
      caption: "Maroc 3 61x50",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Maroc 5 55x46.JPG",
      alt:"Maroc 5 55x46 Philippe Carvin",
      width: 2.3,
      height: 2.6,
      title: "Maroc 5 55x46",
      caption: "Maroc 5 55x46",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Maroc 6 souk 73x60.JPG",
      alt:"Maroc 6 souk 73x60 Philippe Carvin",
      width: 3,
      height: 3.5,
      title: "Maroc 6 souk 73x60",
      caption: "Maroc 6 souk 73x60",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Maroc 7 tbourida 80x80.JPG",
      alt:"Maroc 7 tbourida 80x80 Philippe Carvin",
      width: 4,
      height: 4,
      title: "Maroc 7 tbourida 80x80",
      caption: "Maroc 7 tbourida 80x80",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Maroc petit port 65x46.JPG",
      alt:"Maroc petit port 65x46 Philippe Carvin",
      width: 3.2,
      height: 2.3,
      title: "Maroc petit port 65x46",
      caption: "Maroc petit port 65x46",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Coup de trombone 41x33.JPG",
      alt:"Coup de trombone 41x33 Philippe Carvin",
      width: 1.16,
      height: 1.4,
      title: "Coup de trombone 41x33",
      caption: "Coup de trombone 41x33",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Clown 2 50x65.JPG",
      alt:"Clown 2 50x65 Philippe Carvin",
      width: 2.5,
      height: 3.4,
      title: "Clown 2 50x65",
      caption: "Clown 2 50x65",
      loading: 'lazy'
    }
    ,
    {
      src: "./tableaux/Danseuse 41x33.JPG",
      alt:"Danseuse 41x33 Philippe Carvin",
      width: 2.7,
      height: 3.6,
      title: "Danseuse 41x33",
      caption: "Danseuse 41x33",
      loading: 'lazy'
    }
    ,
    {
      src: "./tableaux/Richard 55x46.JPG",
      alt:"Richard 55x46 Philippe Carvin",
      width: 1.6,
      height: 1.9,
      title: "Richard 55x46",
      caption: "Richard 55x46",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Eleonor 55x46.JPG",
      alt:"Eleonor 55x46 Philippe Carvin",
      width: 3,
      height: 4,
      title: "Eleonor 55x46",
      caption: "Eleonor 55x46",
      loading: 'lazy'
    }
    ,
    {
      src: "./tableaux/Vielle au puit 41x33.JPG",
      alt:"Vielle au puit 41x33 Philippe Carvin",
      width: 1.6,
      height: 2,
      title: "Vielle au puit 41x33",
      caption: "Vielle au puit 41x33",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Pose 4 24x19.JPG",
      alt:"Pose 4 24x19 Philippe Carvin",
      width: 2.3,
      height: 3.1,
      title: "Pose 4 24x19",
      caption: "Pose 4 24x19",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Pose 2 21x29.JPG",
      alt:"Pose 2 21x29 Philippe Carvin",
      width: 3,
      height: 4.5,
      title: "Pose 2 21x29",
      caption: "Pose 2 21x29",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Pose 5 21x29.JPG",
      alt:"Pose 5 21x29 Philippe Carvin",
      width: 1.8,
      height: 2.4,
      title: "Pose 5 21x29",
      caption: "Pose 5 21x29",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Pose 1 21x29.JPG",
      alt:"Pose 1 21x29 Philippe Carvin",
      width: 1.6,
      height: 2.5,
      title: "Pose 1 21x29",
      caption: "Pose 1 21x29",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Clown 21x29.JPG",
      alt:"Clown 21x29 Philippe Carvin",
      width: 2.2,
      height: 3,
      title: "Clown 21x29",
      caption: "Clown 21x29",
      loading: 'lazy'
    }
    ,
    {
      src: "./tableaux/Venise cheveaux de bronze 92x73.JPG",
      alt:"Venise cheveaux de bronze 92x73 Philippe Carvin",
      width: 3.1,
      height: 4,
      title: "Venise cheveaux de bronze 92x73",
      caption: "Venise cheveaux de bronze 92x73",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Tigre 33x41.JPG",
      alt:"Tigre 33x41 Philippe Carvin",
      width: 1.7,
      height: 2.2,
      title: "Tigre 33x41",
      caption: "Tigre 33x41",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Lion 33x41.JPG",
      alt:"Lion 33x41 Philippe Carvin",
      width: 1.6,
      height: 2,
      title: "Lion 33x41",
      caption: "Lion 33x41",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Panthere 55x46.JPG",
      alt:"Panthere 55x46 Philippe Carvin",
      width: 2.3,
      height: 2.7,
      title: "Panthere 55x46",
      caption: "Panthere 55x46",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Blanca 41x33.JPG",
      alt:"Blanca 41x33 Philippe Carvin",
      width: 2.7,
      height: 3.7,
      title: "Blanca 41x33",
      caption: "Blanca 41x33",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Retour de chasse 61x50.JPG",
      alt:"Retour de chasse 61x50 Philippe Carvin",
      width: 3,
      height: 3.6,
      title: "Retour de chasse 61x50",
      caption: "Retour de chasse 61x50",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Phare 1 46x38.JPG",
      alt:"Phare 1 46x38 Philippe Carvin",
      width: 2.2,
      height: 2.7,
      title: "Phare 1 46x38",
      caption: "Phare 1 46x38",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Orage 61x50.JPG",
      alt:"Orage 61x50 Philippe Carvin",
      width: 3,
      height: 4,
      title: "Orage 61x50",
      caption: "Orage 61x50",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Dernier voyage 558x46.JPG",
      alt:"Dernier voyage 558x46 Philippe Carvin",
      width: 2.7,
      height: 2.3,
      title: "Dernier voyage 558x46",
      caption: "Dernier voyage 558x46",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Echouée 33x41.JPG",
      alt:"Echouée 33x41 Philippe Carvin",
      width: 1.9,
      height: 1.5,
      title: "Echouée 33x41",
      caption: "Echouée 33x41",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Attente de la Marée 35x27.JPG",
      alt:"Attente de la Marée 35x27 Philippe Carvin",
      width: 1.6,
      height: 2.1,
      title: "Attente de la Marée 35x27",
      caption: "Attente de la Marée 35x27",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Port aiguillon 38x55.JPG",
      alt:"Port aiguillon 38x55 Philippe Carvin",
      width: 3.5,
      height: 2.4,
      title: "Port aiguillon 38x55",
      caption: "Port aiguillon 38x55",
      loading: 'lazy'
    },
    {
      src: "./tableaux/La Rochelle grosse horloge 73x54.JPG",
      alt:"La Rochelle grosse horloge 73x54 Philippe Carvin",
      width: 3.6,
      height: 2.7,
      title: "La Rochelle grosse horloge 73x54",
      caption: "La Rochelle grosse horloge 73x54",
      loading: 'lazy'
    },
    {
      src: "./tableaux/La Rochelle Port Velin 36x60.JPG",
      alt:"La Rochelle Port Velin 36x60 Philippe Carvin",
      width: 1.8,
      height: 3,
      title: "La Rochelle Port Velin 36x60",
      caption: "La Rochelle Port Velin 36x60",
      loading: 'lazy'
    },
    
    {
      src: "./tableaux/Port Joinville 73x54.JPG",
      alt:"Port Joinville 73x54 Philippe Carvin",
      width: 3.6,
      height: 2.7,
      title: "Port Joinville 73x54",
      caption: "Port Joinville 73x54",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Barque échoué 33x24.JPG",
      alt:"Barque échoué 33x24 Philippe Carvin",
      width: 2.8,
      height: 3.4,
      title: "Barque échouée 33x24",
      caption: "Barque échouée 33x24",
      loading: 'lazy'
    },
    {
      src: "./tableaux/L'Indre 3 92x73.JPG",
      alt:"L'Indre 3 92x73 Philippe Carvin",
      width: 3,
      height: 3.7,
      title: "L'Indre 3 92x73",
      caption: "L'Indre 3 92x73",
      loading: 'lazy'
    },
    {
      src: "./tableaux/L'indre 2 65x54.JPG",
      alt:"L'indre 2 65x54 Philippe Carvin",
      width: 2.5,
      height: 3.1,
      title: "L'indre 2 65x54",
      caption: "L'indre 2 65x54",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Petit pont Azay le rideau 92x73.JPG",
      alt:"Petit pont Azay le rideau 92x73 Philippe Carvin",
      width: 2.3,
      height: 3,
      title: "Petit pont Azay le rideau 92x73",
      caption: "Petit pont Azay le rideau 92x73",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Reflet sur l'Indre 33x24.JPG",
      alt:"Reflet sur l'Indre 33x24 Philippe Carvin",
      width: 2.6,
      height: 3.2,
      title: "Reflet sur l'Indre 33x24",
      caption: "Reflet sur l'Indre 33x24",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Fleuraison 65x24.JPG",
      alt:"Fleuraison 65x24 Philippe Carvin",
      width: 1.4,
      height: 1.7,
      title: "Fleuraison 65x24",
      caption: "Fleuraison 65x24",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Reflet automne 146x114.JPG",
      alt:"Reflet automne 146x114 Philippe Carvin",
      width: 1.2,
      height: 1.8,
      title: "Reflet automne 146x114",
      caption: "Reflet automne 146x114",
      loading: 'lazy'
    },
    {
      src: "./tableaux/L'octroi 65x54.JPG",
      alt:"L'octroi 65x54 Philippe Carvin",
      width: 2.8,
      height: 3.4,
      title: "L'octroi 65x54",
      caption: "L'octroi 65x54",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Moulin Azay le rideau 92x73.JPG",
      alt:"Moulin Azay le rideau 92x73 Philippe Carvin",
      width: 2.7,
      height: 2.2,
      title: "Moulin Azay le rideau 92x73",
      caption: "Moulin Azay le rideau 92x73",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Bibliotheque Tours 73x60.JPG",
      alt:"Bibliotheque Tours 73x60 Philippe Carvin",
      width: 3,
      height: 3.7,
      title: "Bibliotheque Tours 73x60",
      caption: "Bibliotheque Tours 73x60",
      loading: 'lazy'
    },
    {
      src: "./tableaux/L'indre Azay le rideau 3 73x60.JPG",
      alt:"L'indre Azay le rideau 3 73x60 Philippe Carvin",
      width: 2.5,
      height: 3.3,
      title: "L'indre Azay le rideau 3 73x60",
      caption: "L'indre Azay le rideau 3 73x60",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Cave Rochecorbon 55x46.JPG",
      alt:"Cave Rochecorbon 55x46 Philippe Carvin",
      width: 1.85,
      height: 1.85,
      title: "Cave Rochecorbon 55x46",
      caption: "Cave Rochecorbon 55x46",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Départ Inde 69x50.JPG",
      alt:"Départ Inde 69x50 Philippe Carvin",
      width: 2.7,
      height: 1.9,
      title: "Départ Inde 69x50",
      caption: "Départ Inde 69x50",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Roses 35x27.JPG",
      alt:"Roses 35x27 Philippe Carvin",
      width: 1,
      height: 1.2,
      title: "Roses 35x27",
      caption: "Roses 35x27",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Botanique 92x73.JPG",
      alt:"Botanique 92x73 Philippe Carvin",
      width: 2.7,
      height: 3.9,
      title: "Botanique 92x73",
      caption: "Botanique 92x73",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Arbres-thème.JPG",
      alt:"Arbres-thème Philippe Carvin",
      width: 1.6,
      height: 1.1,
      title: "Arbres-thème",
      caption: "Arbres-thème",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Boulot 92x73.JPG",
      alt:"Boulot 92x73 Philippe Carvin",
      width: 1.4,
      height: 1.7,
      title: "Boulot 92x73",
      caption: "Boulot 92x73",
      loading: 'lazy'
    },  
    {
      src: "./tableaux/Matinèe d'automne 61x50.JPG",
      alt:"Matinèe d'automne 61x50 Philippe Carvin",
      width: 1.6,
      height: 1.4,
      title: "Matinèe d'automne 61x50",
      caption: "Matinèe d'automne 61x50",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Allée des grands arbres 92x73.JPG",
      alt:"Allée des grands arbres 92x73 Philippe Carvin",
      width: 1.5,
      height: 1.8,
      title: "Allée des grands arbres 92x73",
      caption: "Allée des grands arbres 92x73",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Arbres 11 92x73.JPG",
      alt:"Arbres 11 92x73 Philippe Carvin",
      width: 1.4,
      height: 1.8,
      title: "Arbres 11 92x73",
      caption: "Arbres 11 92x73",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Arbres 8 92x73.JPG",
      alt:"Arbres 8 92x73 Philippe Carvin",
      width: 1.9,
      height: 1.5,
      title: "Arbres 8 92x73",
      caption: "Arbres 8 92x73",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Petit pont 61x50.JPG",
      alt:"Petit pont 61x50 Philippe Carvin",
      width: 2.3,
      height: 2.8,
      title: "Petit pont 61x50",
      caption: "Petit pont 61x50",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Arbres 9 92x73.JPG",
      alt:"Arbres 9 92x73 Philippe Carvin",
      width: 2.1,
      height: 1.6,
      title: "Arbres 9 92x73",
      caption: "Arbres 9 92x73",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Arbres 10 92x73.JPG",
      alt:"Arbres 10 92x73 Philippe Carvin",
      width: 1.7,
      height: 1.4,
      title: "Arbres 10 92x73",
      caption: "Arbres 10 92x73",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Nuit lumineuse 35x27.JPG",
      alt:"Nuit lumineuse 35x27 Philippe Carvin",
      width: 2.1,
      height: 2.5,
      title: "Nuit lumineuse 35x27",
      caption: "Nuit lumineuse 35x27",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Automnal 92x73.JPG",
      alt:"Automnal 92x73 Philippe Carvin",
      width: 1.8,
      height: 1.4,
      title: "Automnal 92x73",
      caption: "Automnal 92x73",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Le bout du chemin 61x50.JPG",
      alt:"Le bout du chemin 61x50 Philippe Carvin",
      width: 1.3,
      height: 1.6,
      title: "Le bout du chemin 61x50",
      caption: "Le bout du chemin 61x50",
      loading: 'lazy'
    },
    {
    src: "./tableaux/Arbres 7 61x50.JPG",
      alt:"Arbres 7 61x50 Philippe Carvin",
      width: 1.5,
      height: 1.8,
      title: "Arbres 7 61x50",
      caption: "Arbres 7 61x50",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Arbres 5 65x54.JPG",
      alt:"Arbres 5 65x54 Philippe Carvin",
      width: 1.7,
      height: 1.4,
      title: "Arbres 5 65x54",
      caption: "Arbres 5 65x54",
      loading: 'lazy'
    },
    {
    src: "./tableaux/Arbres 6 92x73.JPG",
      alt:"Arbres 6 92x73 Philippe Carvin",
      width: 2,
      height: 1.6,
      title: "Arbres 6 92x73",
      caption: "Arbres 6 92x73",
      loading: 'lazy'
    },
  ];
  